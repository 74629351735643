body {
  font-family: "Roboto", sans-serif;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -388px;
  margin-top: -205px;
  /* min-height: 100vh; */
  /* display: grid; */
  /* justify-content: center; */
  /* align-content: center; */
}

.card {
  background-color: #282c34b5;
  color: white;
  align-items: center;

  max-width: 710px;
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  grid-gap: 2.5rem;

  padding: 2rem;
  box-shadow: 2px 2px 10px #666;
  border: 0.5px solid #666;
  border-radius: 20px;
}

.card2 {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.picture {
  width: 100%;
  border-radius: 50%;
  grid-row: 1/3;
  border: 1px solid #ddd;
}

.name {
  font-size: 4rem;
  margin-bottom: 2rem;
  font-weight: 100;
}

.position,
.status,
.email {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.sites {
  display: flex;
}

.site {
  height: 3rem;
  margin-right: 1.5rem;
}

.button:active {
  transform: translatey(2px);
}

.App-link {
  color: #61dafb;
}

.topLinks > a {
  margin-right: .5rem;
}

@media screen and (max-width: 900px) {
  .container {
    position: fixed;
    top: 50%;
    margin-top: -40vh;
    left: unset;
    margin-left: unset;
  }
  .name {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    font-weight: 100;
  }

  .position,
  .status,
  .email {
    font-size: 1.2rem;
  }

  .card {
    grid-template-columns: 1fr;
    text-align: center;
    grid-gap: 1rem;
    box-shadow: none;
    border: none;
  }

  .sites {
    justify-content: space-evenly;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
