.game-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.game {
  position: relative;
}

.win {
  font-size: 1.5rem;
  margin: 1rem 0px 0.5rem;
}

.pattern-radio {
  margin-bottom: 0.5rem;
}
header {
  font-size: 1.6rem;
  margin: 1rem 0px 1rem;
}

.squares {
  padding: 2rem;
  background: rgb(238, 238, 238);
  box-shadow: rgb(119, 119, 119) 0px 0px 0.5rem inset;
}

.win-text {
  position: absolute;
  width: 100%;
  text-align: center;
}

/* Replaced by styled components */

.row {
  display: flex;
}

.row:not(:first-child) {
  margin-top: 1rem;
}

.square:not(:first-child) {
  margin-left: 1rem;
}

.square {
  cursor: pointer;
}

.square {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  box-shadow: rgb(119, 119, 119) 0px 0.4rem;
  background: rgb(189, 189, 189);
}

.square:hover {
  background: #c3c1b1;
}

.square-clicked {
  background: rgb(255, 235, 59);
  box-shadow: rgb(119, 119, 119) 0px 0px 0.4rem inset;
  transform: translateY(0.4rem);
}

.square-clicked :hover {
  background: #c3c1b1;
}
